import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Radio,
  Input,
  TextArea,
  Button,
  Segment,
  Icon
} from 'semantic-ui-react'

const CardORDetailUX = (props: any) => {
    return(
      <div
        id="CardORDetail"
        style={{width:"100%",}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={2}>
              <label
                style={{fontWeight: "bold", margin: 0}}>
                วิธีระงับความรู้สึก
              </label>
              <label
                style={{color: "red"}}>
                *
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                fluid={true}
                name="anesthesia_method"
                onChange={props.onChangeValue}
                options={props.anesthesiaMethodOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.anesthesiaMethod || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: "flex", alignItems: "center"}}>
              <FormField
                className="required"
                style={{minWidth: "max-content"}}>
                <label
                  style={{color: "#056EBD",}}>
                  Estimate time
                </label>
              </FormField>
              <Input
                fluid={true}
                name="estimate_operation_hour"
                onChange={props.onChangeValue}
                style={{ minWidth: "6rem", maxWidth: "6rem"}}
                type="number"
                value={props.estimateOperationHour || ""}>
              </Input>
              <label
                style={{fontWeight: "normal",color: "#056EBD", marginLeft: "0.75rem", marginRight: "1.25rem"  }}>
                hr.
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{display: "flex", alignItems: "center"}}>
              <Input
                fluid={true}
                name="estimate_operation_minute"
                onChange={props.onChangeValue}
                style={{ minWidth: "6rem", maxWidth: "6rem"}}
                type="number"
                value={props.estimateOperationMinute || ""}>
              </Input>
              <label
                style={{fontWeight: "normal",color: "#056EBD", minWidth: "max-content" }}>
                min
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{color: "#056EBD",width: "100%", maxWidth: "max-content"}}>
                วันที่ผ่าตัด
              </label>
              <div
                style={{width: "100%"}}>
                {props.DateTextBox}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD",minWidth: "max-content"}}>
                เวลา เริ่มต้น
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.StartTime}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD", minWidth: "max-content"}}>
                เวลา สิ้นสุด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.EndTime}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD"}}>
                Location
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.config?.disableLocation || props.config?.allowedEdit || false}
                fluid={true}
                icon={null}
                name="location"
                onChange={props.onChangeValue}
                options={props.orLocationOptions || []}
                selection={true}
                style={{width: "100%", ...(props.location === "" && { display: "none" } ) }}
                value={props.location || "ไปเลือกที่วันเวลาผ่าตัด"}>
              </Dropdown>
              <Input
                disabled={true}
                fluid={true}
                style={{width: "100%", ...(props.location !== "" && { display: "none" } ) }}
                value="ไปเลือกที่วันเวลาผ่าตัด">
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD"}}>
                Room
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.config?.disabledRoom || props.config?.allowedEdit || false}
                fluid={true}
                icon={null}
                name="operating_room"
                onChange={props.onChangeValue}
                options={props.operatingRoomOptions || []}
                selection={true}
                style={{width: "100%", ...(props.operatingRoom === "" && { display: "none" } ) }}
                value={props.operatingRoom || "ไปเลือกที่วันเวลาผ่าตัด"}>
              </Dropdown>
              <Input
                disabled={true}
                fluid={true}
                style={{width: "100%", ...(props.operatingRoom !== "" && { display: "none" } ) }}
                value="ไปเลือกที่วันเวลาผ่าตัด">
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{fontWeight: "bold"}}>
                Procedure Type
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                fluid={true}
                name="procedure_type"
                onChange={props.onChangeValue}
                options={props.procedureTypeOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.procedureType || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.config?.hideProcedureChecklist?"none":""}}>
              <Checkbox
                checked={props.frozenSection || false}
                label="Sentinel lymph node biopsy & Frozen section"
                name="frozen_section"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.config?.hideProcedureChecklist?"none":""}}>
              <Checkbox
                checked={props.mammogram || false}
                label="Needle Localized; Mammogram"
                name="mammogram"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={2}>
              <label>
                ยาที่ต้องหยุดก่อนผ่าตัด
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{_minWidth: "290px",minWidth: "max-content",gap:"10px"}}
              width={5}>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.NO_DRUG}
                label="ไม่ได้ใช้ยา"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.NO_DRUG, name: "anti_platelet_coagulant.value" })}
                style={{minWidth: "max-content"}}>
              </Radio>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.CUT_DRUG}
                label="ไม่ต้องงด"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.CUT_DRUG, name:"anti_platelet_coagulant.value"})}
                style={{minWidth: "max-content"}}>
              </Radio>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET}
                label="งดใช้ยา"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:"anti_platelet_coagulant.value"})}
                style={{minWidth: "max-content"}}>
              </Radio>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Checkbox
                checked={props.antiPlateletCoagulant?.drug || false}
                label="Anti-Platelet/ Anti-Coagulant"
                name="anti_platelet_coagulant.drug"
                onChange={props.onChangeValue}
                style={{width: "calc(100% - -3rem)", maxWidth: "max-content"}}>
              </Checkbox>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET}
                label="ใช้ Anti-Platelet/ Anti-Coagulant"
                onChange={(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:"anti_platelet_coagulant.value"})}
                style={{minWidth: "max-content", marginRight: "1rem", display: "none" }}>
              </Radio>
              <Input
                disabled={!props.antiPlateletCoagulant?.drug || false}
                fluid={true}
                name="anti_platelet_coagulant.remark"
                onChange={props.onChangeValue}
                style={{width:"100%"}}
                value={props.remark || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                งดใช้ยาตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxAntiPlatelet}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              style={{_minWidth: "290px",minWidth: "max-content"}}
              width={5}>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Checkbox
                checked={props.antiPlateletCoagulant?.other_drug || false}
                label="ยาอื่น ๆ"
                name="anti_platelet_coagulant.other_drug"
                onChange={props.onChangeValue}
                style={{width: "calc(100% - -3rem)", maxWidth: "max-content"}}>
              </Checkbox>
              <TextArea
                disabled={!props.antiPlateletCoagulant?.other_drug || false}
                name="anti_platelet_coagulant.other_drug_remark"
                onChange={props.onChangeValue}
                value={props.antiPlateletCoagulant?.other_drug_remark || ""}>
              </TextArea>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                งดใช้ยาตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxAntiPlateletOther}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={2}>
              <label
                style={{fontWeight: "bold"}}>
                NPO
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{_minWidth: "290px",minWidth: "max-content", gap:"10px"}}
              width={5}>
              <Radio
                checked={props.antiPlateletCoagulant?.npo === "NO_NPO"}
                label="ไม่ต้อง NPO"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value: "NO_NPO", name: "anti_platelet_coagulant.npo" })}
                style={{minWidth: "max-content"}}>
              </Radio>
              <Radio
                checked={props.antiPlateletCoagulant?.npo === "NPO"}
                label="NPO ทั้งหมด"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value: "NPO", name: "anti_platelet_coagulant.npo" })}
                style={{minWidth: "max-content"}}>
              </Radio>
              <Radio
                checked={props.antiPlateletCoagulant?.npo === "NO_NPO_DRUG"}
                label="NPO ยกเว้น ยา"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value: "NO_NPO_DRUG", name: "anti_platelet_coagulant.npo" })}
                style={{minWidth: "max-content"}}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                งดตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxNPO}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.TimeTextBoxNPO}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: "none"}}>
            <FormField
              inline={true}
              width={2}>
              <label>
                
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "290px"}}>
            </FormField>
            <FormField
              style={{alignItems:"baseline"}}
              width={5}>
              <Checkbox
                checked={props.otherPreOpMeds?.value || false}
                label="ยาอื่นๆ"
                name="other_pre_op_meds.value"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
              <TextArea
                disabled={!props.otherPreOpMeds?.value}
                name="other_pre_op_meds.remark"
                onChange={props.onChangeValue}
                rows={2}
                value={props.otherPreOpMeds?.remark || ""}>
              </TextArea>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                งดใช้ยาตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxOtherDrug}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: "none"}}>
            <FormField
              className="required"
              inline={true}
              width={2}>
              <label>
                NPO
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.npoValue === props.NOTHING_THROUGH_MOUTH?.NO_NPO}
                label="ไม่ต้อง NPO"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.NO_NPO, name: "npo.value" })}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.npoValue === props.NOTHING_THROUGH_MOUTH?.NPO_ALL}
                label="NPO ทั้งหมด"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.NPO_ALL, name: "npo.value" })}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.npoValue === props.NOTHING_THROUGH_MOUTH?.EXCEPT_MEDS}
                label="NPO ยกเว้น ยา"
                onChange={(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.EXCEPT_MEDS, name: "npo.value" })}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                งดตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxNPO}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.TimeNPO}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Contrast Media
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                name="contrast_media"
                onChange={props.onChangeValue}
                options={props.cmOptions || {}}
                selection={true}
                style={{width: "100%"}}
                value={props.contrastMedia || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ASA PS
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Dropdown
                name="asa_class"
                onChange={props.onChangeValue}
                options={props.asaClassOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.asaClass || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Special Equipment
              </label>
            </FormField>
            <FormField
              inline={{width: "100%"}}
              width={12}>
              <Dropdown
                multiple={true}
                name="special_equipments"
                onChange={props.onChangeValue}
                options={props.specialEquipmentOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.specialEquipments || []}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Special Equipment detail
              </label>
            </FormField>
            <FormField
              inline={{width: "100%"}}
              width={12}>
              <TextArea
                name="detail.special_equipments_detail"
                onChange={props.onChangeValue}
                value={props.detail?.special_equipments_detail || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                คำแนะนำในการปฏิบัติตัวเพิ่มเติม
              </label>
            </FormField>
            <FormField>
              <Button
                color="blue"
                onClick={props.onAddNote}>
                เพิ่มคำแนะนำ
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <TextArea
                name="remark"
                onChange={props.onChangeValue}
                rows={3}
                value={props.remarkDetail || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Anticipate Blood loss
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.isLess_500 || false}
                label=" < 500 ml in adult or < 7 ml/kg in children"
                name="anticipated_blood_loss.is_less_500"
                onChange={props.onChangeValue}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.isMore_500 || false}
                label=" > 500 ml in adult or > 7 ml/kg in children"
                name="anticipated_blood_loss.is_more_500"
                onChange={props.onChangeValue}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <div>
                Define
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                name="anticipated_blood_loss.define"
                onChange={props.onChangeValue}
                type="number"
                value={props.define || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                mL
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Blood Request
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                disabled={props.config?.showBloodRequest}
                fluid={true}
                name="blood_request"
                onChange={props.onChangeValue}
                options={props.bloodRequestOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.bloodRequest || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="purple"
                disabled={!props.config?.showOrderBloodRequest && !props.canOrderBloodBank}
                onClick={props.onOrderBloodRequest}>
                สั่งจองเลือด
              </Button>
            </FormField>
            <FormField
              inline={true}
              style={{ ...( props.bloodRequest !== "is_yes" && { display: "none" } ) }}>
              <Checkbox
                checked={props.is_blood_reserve}
                label="สำรองเลือด (ไม่คิดค่าใช้จ่าย)"
                name="is_blood_reserve"
                onChange={props.onChangeValueBloodRequest}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                Blood Component
              </label>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Input
                name="blood_component"
                onChange={props.onChangeValueBloodRequest}
                readOnly={!props.is_blood_reserve}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.blood_component}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                นัดหมาย Cross-match
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                readOnly={true}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.cross_match_detail}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                วันเจาะเลือด
              </label>
            </FormField>
            <FormField
              inline={true}
              width="3">
              <Input
                readOnly={true}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.cross_match_date}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label>
                Pre-Operative Medical Assessment
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Button
                color="orange"
                icon="check square outline"
                onClick={props.onOpenModRisk}
                size="mini">
                Assessment Tool
              </Button>
            </FormField>
          </FormGroup>
          <Segment>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label>
                  Assessment: 
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{ fontWeight: "normal" }}>
                  1. Cardiovascular and Pulmonary
                </label>
              </FormField>
              <FormField
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  fluid={true}
                  name="pre_op_medical_assessment.data.cardiovascular"
                  onChange={props.onChangeValue}
                  options={props.preAssessmentOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%" }}
                  value={props.preOpMedicalAssessment?.data?.cardiovascular || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{ fontWeight: "normal" }}>
                  
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{ fontWeight: "normal" }}>
                   2. Risk of Bleeding
                </label>
              </FormField>
              <FormField
                width={1}>
                <div
                  style={{ display: props.preOpMedicalAssessment?.data?.risk_bleeding?.edit_by ? "" : "none", textAlign: "right", width: "100%" }}>
                  
                  <Icon
                    name="check square outline green">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  clearable={true}
                  disabled={props.preOpMedicalAssessment?.data?.risk_bleeding?.summary}
                  fluid={true}
                  name="pre_op_medical_assessment.data.bleeding"
                  onChange={props.onChangeValue}
                  options={props.preAssessmentOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.preOpMedicalAssessment?.data?.risk_bleeding?.summary || props.preOpMedicalAssessment?.data?.bleeding || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{ fontWeight: "normal" }}>
                  {`${props.preOpMedicalAssessment?.data?.risk_bleeding?.edit_by || ""}    ${props.preOpMedicalAssessment?.data?.risk_bleeding?.edited || ""}`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{ fontWeight: "normal" }}>
                   3. Risk of DVT
                </label>
              </FormField>
              <FormField
                width={1}>
                <div
                  style={{ display: props.preOpMedicalAssessment?.data?.risk_dvt?.edit_by ? "" : "none", textAlign: "right", width: "100%" }}>
                  
                  <Icon
                    name="check square outline green">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  clearable={true}
                  disabled={props.preOpMedicalAssessment?.data?.risk_dvt?.summary}
                  fluid={true}
                  name="pre_op_medical_assessment.data.dvt"
                  onChange={props.onChangeValue}
                  options={props.preAssessmentOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.preOpMedicalAssessment?.data?.risk_dvt?.summary || props.preOpMedicalAssessment?.data?.dvt || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{ fontWeight: "normal" }}>
                  {`${props.preOpMedicalAssessment?.data?.risk_dvt?.edit_by || ""}    ${props.preOpMedicalAssessment?.data?.risk_dvt?.edited || ""}`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{ fontWeight: "normal" }}>
                  4. ASA Classification
                </label>
              </FormField>
              <FormField
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={true}
                  value={props.asaClassText || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{ fontWeight: "normal" }}>
                  
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{ fontWeight: "normal" }}>
                  5. Co-mobidities
                </label>
              </FormField>
              <FormField
                inline={true}
                width={11}>
                <TextArea
                  name="pre_op_medical_assessment.data.co_mobidities"
                  onChange={props.onChangeValue}
                  rows={3}
                  value={props.preOpMedicalAssessment?.data?.co_mobidities || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ background: "rgba(117, 229, 255, 0.32)", padding: "10px", width: "300px" }}>
              <FormField
                inline={true}>
                <label>
                  Proceed with surgery
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.preOpMedicalAssessment?.data?.proceed_with_surgery === "yes"}
                  label="Yes"
                  name="data.proceed_with_surgery"
                  onChange={(e: any, v: any)=> props.onChangeValue(e, { value: "yes", name: "pre_op_medical_assessment.data.proceed_with_surgery" })}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.preOpMedicalAssessment?.data?.proceed_with_surgery === "no"}
                  label="No"
                  name="data.proceed_with_surgery"
                  onChange={(e: any, v: any)=> props.onChangeValue(e, { value: "no", name: "pre_op_medical_assessment.data.proceed_with_surgery" })}>
                </Radio>
              </FormField>
            </FormGroup>
          </Segment>
        </div>
      </div>
    )
}


export default CardORDetailUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 16 2025 09:26:29 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORDetail"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีระงับความรู้สึก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", margin: 0}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "anesthesia_method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaMethodOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesthesiaMethod || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\",minWidth: \"max-content\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\", minWidth: \"max-content\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideProcedureChecklist?\"none\":\"\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideProcedureChecklist?\"none\":\"\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "procedure_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.procedureTypeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.procedureType || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Checkbox",
      "parent": 47,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.frozenSection || false"
        },
        "label": {
          "type": "value",
          "value": "Sentinel lymph node biopsy & Frozen section"
        },
        "name": {
          "type": "value",
          "value": "frozen_section"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Checkbox",
      "parent": 48,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.mammogram || false"
        },
        "label": {
          "type": "value",
          "value": "Needle Localized; Mammogram"
        },
        "name": {
          "type": "value",
          "value": "mammogram"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Request"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.showBloodRequest"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "blood_request"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.bloodRequestOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.bloodRequest || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "Anticipate Blood loss"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Radio",
      "parent": 74,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isLess_500 || false"
        },
        "label": {
          "type": "value",
          "value": " < 500 ml in adult or < 7 ml/kg in children"
        },
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.is_less_500"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Radio",
      "parent": 75,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMore_500 || false"
        },
        "label": {
          "type": "value",
          "value": " > 500 ml in adult or > 7 ml/kg in children"
        },
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.is_more_500"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Define"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "mL"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 77,
      "props": {
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.define"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.define || \"\""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{_minWidth: \"290px\",minWidth: \"max-content\",gap:\"10px\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ยาที่ต้องหยุดก่อนผ่าตัด"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Radio",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.NO_DRUG"
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้ใช้ยา"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.NO_DRUG, name: \"anti_platelet_coagulant.value\" })"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "งดใช้ยาตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxAntiPlatelet"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Contrast Media"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA PS"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Dropdown",
      "parent": 101,
      "props": {
        "name": {
          "type": "value",
          "value": "contrast_media"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.cmOptions || {}"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.contrastMedia || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Dropdown",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": "asa_class"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.asaClassOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.asaClass || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Equipment detail"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "TextArea",
      "parent": 116,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "value": {
          "type": "code",
          "value": "props.remarkDetail || \"\""
        }
      },
      "seq": 117,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 11,
      "props": {
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Button",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Input",
      "parent": 123,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "estimate_operation_hour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"6rem\", maxWidth: \"6rem\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimateOperationHour || \"\""
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Input",
      "parent": 125,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "estimate_operation_minute"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"6rem\", maxWidth: \"6rem\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimateOperationMinute || \"\""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Dropdown",
      "parent": 140,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledRoom || props.config?.allowedEdit || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "null"
        },
        "name": {
          "type": "value",
          "value": "operating_room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.operatingRoomOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.operatingRoom === \"\" && { display: \"none\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.operatingRoom || \"ไปเลือกที่วันเวลาผ่าตัด\""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Dropdown",
      "parent": 138,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableLocation || props.config?.allowedEdit || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "null"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orLocationOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.location === \"\" && { display: \"none\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.location || \"ไปเลือกที่วันเวลาผ่าตัด\""
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งจองเลือด"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.showOrderBloodRequest && !props.canOrderBloodBank"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOrderBloodRequest"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Component"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมาย Cross-match"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเจาะเลือด"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Input",
      "parent": 149,
      "props": {
        "name": {
          "type": "value",
          "value": "blood_component"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValueBloodRequest"
        },
        "readOnly": {
          "type": "code",
          "value": "!props.is_blood_reserve"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.blood_component"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Input",
      "parent": 156,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.cross_match_detail"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 160,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.cross_match_date"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Radio",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.CUT_DRUG"
        },
        "label": {
          "type": "value",
          "value": "ไม่ต้องงด"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.CUT_DRUG, name:\"anti_platelet_coagulant.value\"})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Radio",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET"
        },
        "label": {
          "type": "value",
          "value": "งดใช้ยา"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:\"anti_platelet_coagulant.value\"})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Checkbox",
      "parent": 168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.drug || false"
        },
        "label": {
          "type": "value",
          "value": "Anti-Platelet/ Anti-Coagulant"
        },
        "name": {
          "type": "value",
          "value": "anti_platelet_coagulant.drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(100% - -3rem)\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 172,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"290px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Checkbox",
      "parent": 175,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.otherPreOpMeds?.value || false"
        },
        "label": {
          "type": "value",
          "value": "ยาอื่นๆ"
        },
        "name": {
          "type": "value",
          "value": "other_pre_op_meds.value"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Input",
      "parent": 168,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.antiPlateletCoagulant?.drug || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "anti_platelet_coagulant.remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "TextArea",
      "parent": 175,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.otherPreOpMeds?.value"
        },
        "name": {
          "type": "value",
          "value": "other_pre_op_meds.remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "value": {
          "type": "code",
          "value": "props.otherPreOpMeds?.remark || \"\""
        }
      },
      "seq": 179,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "label",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": "งดใช้ยาตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxOtherDrug"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 184,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Radio",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.npoValue === props.NOTHING_THROUGH_MOUTH?.NO_NPO"
        },
        "label": {
          "type": "value",
          "value": "ไม่ต้อง NPO"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.NO_NPO, name: \"npo.value\" })"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Radio",
      "parent": 187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.npoValue === props.NOTHING_THROUGH_MOUTH?.NPO_ALL"
        },
        "label": {
          "type": "value",
          "value": "NPO ทั้งหมด"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.NPO_ALL, name: \"npo.value\" })"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Radio",
      "parent": 188,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.npoValue === props.NOTHING_THROUGH_MOUTH?.EXCEPT_MEDS"
        },
        "label": {
          "type": "value",
          "value": "NPO ยกเว้น ยา"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value:  props.NOTHING_THROUGH_MOUTH?.EXCEPT_MEDS, name: \"npo.value\" })"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "label",
      "parent": 193,
      "props": {
        "children": {
          "type": "value",
          "value": "งดตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxNPO"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 196,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TimeNPO"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Radio",
      "parent": 168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET"
        },
        "label": {
          "type": "value",
          "value": "ใช้ Anti-Platelet/ Anti-Coagulant"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:\"anti_platelet_coagulant.value\"})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\", display: \"none\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\",width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",color: \"#056EBD\", minWidth: \"max-content\" }"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",color: \"#056EBD\", marginLeft: \"0.75rem\", marginRight: \"1.25rem\"  }"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Input",
      "parent": 138,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.location !== \"\" && { display: \"none\" } ) }"
        },
        "value": {
          "type": "value",
          "value": "ไปเลือกที่วันเวลาผ่าตัด"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Input",
      "parent": 140,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.operatingRoom !== \"\" && { display: \"none\" } ) }"
        },
        "value": {
          "type": "value",
          "value": "ไปเลือกที่วันเวลาผ่าตัด"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 123,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 211,
      "props": {
        "children": {
          "type": "value",
          "value": "Estimate time"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\",}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 213,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormField",
      "parent": 222,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{_minWidth: \"290px\",minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Checkbox",
      "parent": 229,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.other_drug || false"
        },
        "label": {
          "type": "value",
          "value": "ยาอื่น ๆ"
        },
        "name": {
          "type": "value",
          "value": "anti_platelet_coagulant.other_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(100% - -3rem)\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "label",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": "งดใช้ยาตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxAntiPlateletOther"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "TextArea",
      "parent": 229,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.antiPlateletCoagulant?.other_drug || false"
        },
        "name": {
          "type": "value",
          "value": "anti_platelet_coagulant.other_drug_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.other_drug_remark || \"\""
        }
      },
      "seq": 237,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{_minWidth: \"290px\",minWidth: \"max-content\", gap:\"10px\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Radio",
      "parent": 238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.npo === \"NO_NPO\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ต้อง NPO"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value: \"NO_NPO\", name: \"anti_platelet_coagulant.npo\" })"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Radio",
      "parent": 238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.npo === \"NPO\""
        },
        "label": {
          "type": "value",
          "value": "NPO ทั้งหมด"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value: \"NPO\", name: \"anti_platelet_coagulant.npo\" })"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Radio",
      "parent": 238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletCoagulant?.npo === \"NO_NPO_DRUG\""
        },
        "label": {
          "type": "value",
          "value": "NPO ยกเว้น ยา"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value: \"NO_NPO_DRUG\", name: \"anti_platelet_coagulant.npo\" })"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "งดตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxNPO"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TimeTextBoxNPO"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormField",
      "parent": 250,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "label",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-Operative Medical Assessment"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "FormField",
      "parent": 250,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Button",
      "parent": 253,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment Tool"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "icon": {
          "type": "value",
          "value": "check square outline"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModRisk"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "Segment",
      "parent": 1,
      "props": {
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 257,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "label",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment: "
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "FormField",
      "parent": 260,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "label",
      "parent": 261,
      "props": {
        "children": {
          "type": "value",
          "value": "1. Cardiovascular and Pulmonary"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 260,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Dropdown",
      "parent": 263,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.cardiovascular"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.cardiovascular || \"\""
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "FormField",
      "parent": 260,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "label",
      "parent": 266,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormField",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "label",
      "parent": 269,
      "props": {
        "children": {
          "type": "value",
          "value": " 2. Risk of Bleeding"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormField",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "FormField",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 274,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.preOpMedicalAssessment?.data?.risk_bleeding?.edit_by || \"\"}    ${props.preOpMedicalAssessment?.data?.risk_bleeding?.edited || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "FormField",
      "parent": 276,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 277,
      "props": {
        "children": {
          "type": "value",
          "value": " 3. Risk of DVT"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "FormField",
      "parent": 276,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "FormField",
      "parent": 276,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "label",
      "parent": 282,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.preOpMedicalAssessment?.data?.risk_dvt?.edit_by || \"\"}    ${props.preOpMedicalAssessment?.data?.risk_dvt?.edited || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "FormField",
      "parent": 284,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "label",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": "4. ASA Classification"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormField",
      "parent": 284,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "FormField",
      "parent": 284,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 290,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Dropdown",
      "parent": 272,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.risk_bleeding?.summary"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.bleeding"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.risk_bleeding?.summary || props.preOpMedicalAssessment?.data?.bleeding || \"\""
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "Dropdown",
      "parent": 280,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.risk_dvt?.summary"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.dvt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.risk_dvt?.summary || props.preOpMedicalAssessment?.data?.dvt || \"\""
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 295,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "label",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": "5. Co-mobidities"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "FormField",
      "parent": 295,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "TextArea",
      "parent": 298,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.co_mobidities"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "value": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.co_mobidities || \"\""
        }
      },
      "seq": 299,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "FormGroup",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ background: \"rgba(117, 229, 255, 0.32)\", padding: \"10px\", width: \"300px\" }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "label",
      "parent": 301,
      "props": {
        "children": {
          "type": "value",
          "value": "Proceed with surgery"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Radio",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.proceed_with_surgery === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "data.proceed_with_surgery"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value: \"yes\", name: \"pre_op_medical_assessment.data.proceed_with_surgery\" })"
        },
        "value": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "Input",
      "parent": 288,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "value",
          "value": ""
        },
        "readonly": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.asaClassText || \"\""
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Radio",
      "parent": 307,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preOpMedicalAssessment?.data?.proceed_with_surgery === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "data.proceed_with_surgery"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: any)=> props.onChangeValue(e, { value: \"no\", name: \"pre_op_medical_assessment.data.proceed_with_surgery\" })"
        },
        "value": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Icon",
      "parent": 316,
      "props": {
        "name": {
          "type": "value",
          "value": "check square outline green"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "Icon",
      "parent": 315,
      "props": {
        "name": {
          "type": "value",
          "value": "check square outline green"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 315,
      "name": "div",
      "parent": 317,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.preOpMedicalAssessment?.data?.risk_bleeding?.edit_by ? \"\" : \"none\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 316,
      "name": "div",
      "parent": 318,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.preOpMedicalAssessment?.data?.risk_dvt?.edit_by ? \"\" : \"none\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "FormField",
      "parent": 268,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormField",
      "parent": 276,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "FormField",
      "parent": 260,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "FormField",
      "parent": 284,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "FormField",
      "parent": 321,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "label",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Equipment"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "FormField",
      "parent": 321,
      "props": {
        "inline": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "Dropdown",
      "parent": 324,
      "props": {
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "special_equipments"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.specialEquipmentOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.specialEquipments || []"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "TextArea",
      "parent": 109,
      "props": {
        "name": {
          "type": "value",
          "value": "detail.special_equipments_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.special_equipments_detail || \"\""
        }
      },
      "seq": 326,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 327,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...( props.bloodRequest !== \"is_yes\" && { display: \"none\" } ) }"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "Checkbox",
      "parent": 327,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.is_blood_reserve"
        },
        "label": {
          "type": "value",
          "value": "สำรองเลือด (ไม่คิดค่าใช้จ่าย)"
        },
        "name": {
          "type": "value",
          "value": "is_blood_reserve"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValueBloodRequest"
        }
      },
      "seq": 328,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
