import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardORQueueUX = (props: any) => {
    return(
      <div
        id="CardORQueue"
        style={{ padding: "15px" }}>
        <div
          className="ui form"
          id="CardORQueueUX-div-149"
          style={{display: props.showFilterORQueue  || props.showFilterWardQueue ? "": ""}}>
          
          <FormGroup
            id="CardORQueueUX-FormGroup-150">
            <FormField
              id="CardORQueueUX-FormField-154"
              width={2}>
              <label
                id="CardORQueueUX-label-198"
                style={{fontWeight: "normal"}}>
                HN
              </label>
              <div
                id="CardORQueueUX-div-166">
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-156"
              width={3}>
              <label
                id="CardORQueueUX-label-199"
                style={{fontWeight: "normal"}}>
                ชื่อ - นามสกุล
              </label>
              <Input
                fluid={true}
                id="CardORQueueUX-Input-168"
                readOnly={true}
                value={props.ORQueueSequence?.orFilter?.patientFullName || ""}>
              </Input>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-158"
              width={3}>
              <label
                id="CardORQueueUX-label-200"
                style={{fontWeight: "normal"}}>
                แพทย์ผ่าตัด
              </label>
              <div
                id="CardORQueueUX-div-170">
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-160"
              width={2}>
              <label
                id="CardORQueueUX-label-201"
                style={{fontWeight: "normal"}}>
                Case
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="CardORQueueUX-Dropdown-172"
                name="typesCases"
                onChange={props.onChangeTypesCases}
                options={props.operatingCaseOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.typesCases || null}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-162"
              width={3}>
              <label
                id="CardORQueueUX-label-202"
                style={{fontWeight: "normal"}}>
                Status
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="CardORQueueUX-Dropdown-173"
                name="status"
                onChange={props.onChangeData}
                options={props.masterOptions?.operatingStatus || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.status || null}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-213"
              width={3}>
              <Checkbox
                checked={props.isShowActiveStatus}
                disabled={props.ORQueueSequence?.orFilter?.status !== ""}
                id="CardORQueueUX-Checkbox-214"
                label="แสดงรายการที่ไม่ถูกยกเลิก"
                name="isShowActiveStatus"
                onChange={props.onChangeIsShowActiveStatus}
                style={{ marginTop: "26px" }}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-163">
              <label
                id="CardORQueueUX-label-209"
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div
                id="CardORQueueUX-div-175">
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-164">
              <label
                id="CardORQueueUX-label-210"
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div
                id="CardORQueueUX-div-176">
                {props.buttonPrint}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardORQueueUX-FormGroup-151">
            <FormField
              id="CardORQueueUX-FormField-177"
              width={3}>
              <label
                id="CardORQueueUX-label-203"
                style={{fontWeight: "normal"}}>
                วันที่ผ่าตัด
              </label>
              <div
                id="CardORQueueUX-div-188"
                style={{width: "100%"}}>
                {props.startORDate}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-178">
              <label
                id="CardORQueueUX-label-211">
                {"\u00a0"}
              </label>
              <div
                id="CardORQueueUX-div-189"
                style={{marginTop: "0.95rem", minWidth: "max-content"}}>
                ถึง
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-179"
              width={3}>
              <label
                id="CardORQueueUX-label-204"
                style={{fontWeight: "normal"}}>
                {"\u00a0"}
              </label>
              <div
                id="CardORQueueUX-div-190"
                style={{width: "100%"}}>
                {props.endORDate}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-181"
              width={3}>
              <label
                id="CardORQueueUX-label-205"
                style={{fontWeight: "normal"}}>
                วันจองห้องผ่าตัด
              </label>
              <div
                id="CardORQueueUX-div-192"
                style={{width: "100%"}}>
                {props.creatORDate}
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-183"
              width={3}>
              <label
                id="CardORQueueUX-label-206"
                style={{fontWeight: "normal"}}>
                Location
              </label>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                id="CardORQueueUX-Dropdown-194"
                name="location"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.locationOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-185"
              width={3}>
              <label
                id="CardORQueueUX-label-207"
                style={{fontWeight: "normal"}}>
                Room
              </label>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                id="CardORQueueUX-Dropdown-196"
                name="room"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.roomOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-186">
              <Checkbox
                checked={props.ORQueueSequence?.orFilter?.anesMethod}
                id="CardORQueueUX-Checkbox-197"
                label="ใช้วิธีระงับความรู้สึก"
                name="anesMethod"
                onChange={props.onChangeAnesMethod}
                style={{marginTop: "15px", minWidth: "max-content"}}>
              </Checkbox>
              <Checkbox
                checked={props.ORQueueSequence?.orFilter?.no_date_no_room}
                id="CardORQueueUX-Checkbox-212"
                label="OR Request ไม่ระบุนัดหมาย"
                name="no_date_no_room"
                onChange={props.onChangeNoDateRoom}
                style={{marginTop: "0.65rem", minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form small"
          id="CardORQueueUX-div-132"
          style={{display: props.showFilterDrugQueue ? "": "none"}}>
          
          <FormGroup
            id="CardORQueueUX-FormGroup-133"
            inline={true}>
            <FormField
              id="CardORQueueUX-FormField-134"
              inline={true}>
              <div
                id="CardORQueueUX-div-139">
                วันที่ผ่าตัด
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-135"
              inline={true}
              width="2">
              <div
                id="CardORQueueUX-div-144"
                style={{width: "100%"}}>
                
                <DateTextBox
                  id="CardORQueueUX-DateTextBox-146"
                  onChange={props.onDateChange("start_date")}
                  value={props.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-136"
              inline={true}>
              <div
                id="CardORQueueUX-div-140"
                style={{width: "100%", textAlign: "center" }}>
                ถึง
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-137"
              inline={true}
              width="2">
              <div
                id="CardORQueueUX-div-145"
                style={{width: "100%"}}>
                
                <DateTextBox
                  id="CardORQueueUX-DateTextBox-147"
                  onChange={props.onDateChange("end_date")}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              id="CardORQueueUX-FormField-138"
              inline={true}
              width="6">
              <div
                id="CardORQueueUX-div-148"
                style={{ marginLeft: "20px" }}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.orOrderItems}
          getTrProps={props.getSelectedRow}
          headers={props.showFilterORQueue ? "วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Case, Operation, Special Equipment, Blood, Anesthesia, Status," : "วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Case, Operation, Special Equipment, Blood, Anesthesia, Status"}
          id="CardORQueueUX-Table-51"
          keys={props.showFilterORQueue ? "surgeryDatetime, locationRoom, hn, patientNameCheckinCode, team_detail_label, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote, menu" : "surgeryDatetime, locationRoom, hn, patientNameCheckinCode, team_detail_label, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote"}
          minRows="6"
          showPagination={false}
          style={{height: "calc(100dvh - 20.5rem)"}}
          widths={props.showFilterORQueue  ? "^90,^110,^80,^120,^120,^110,^110,^110,^100,^80,^80,^100,^80"  : "^100,^110,^80,^150,^120,^110,^110,^110,^120,^80,^80,^100"}>
        </Table>
      </div>
    )
}


export default CardORQueueUX

export const screenPropsDefault = {"current":null}

/* Date Time : Fri Jan 31 2025 16:36:55 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueue"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.orOrderItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getSelectedRow"
        },
        "headers": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Case, Operation, Special Equipment, Blood, Anesthesia, Status,\" : \"วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Case, Operation, Special Equipment, Blood, Anesthesia, Status\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Table-51"
        },
        "keys": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"surgeryDatetime, locationRoom, hn, patientNameCheckinCode, team_detail_label, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote, menu\" : \"surgeryDatetime, locationRoom, hn, patientNameCheckinCode, team_detail_label, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote\""
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 20.5rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.showFilterORQueue  ? \"^90,^110,^80,^120,^120,^110,^110,^110,^100,^80,^80,^100,^80\"  : \"^100,^110,^80,^150,^120,^110,^110,^110,^120,^80,^80,^100\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "label": "divgrid",
      "name": "div",
      "parent": 87,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-84"
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-87"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-132"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterDrugQueue ? \"\": \"none\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormGroup-133"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-134"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-135"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 133,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-136"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 133,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-137"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 133,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-138"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-139"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-140"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-144"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-145"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 146,
      "name": "DateTextBox",
      "parent": 144,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-DateTextBox-146"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 147,
      "name": "DateTextBox",
      "parent": 145,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-DateTextBox-147"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"end_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-148"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-149"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterORQueue  || props.showFilterWardQueue ? \"\": \"\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormGroup-150"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormGroup-151"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-154"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-156"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-158"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-160"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-162"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-163"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-164"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-166"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 156,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Input-168"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-170"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Dropdown",
      "parent": 160,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Dropdown-172"
        },
        "name": {
          "type": "value",
          "value": "typesCases"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTypesCases"
        },
        "options": {
          "type": "code",
          "value": "props.operatingCaseOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.typesCases || null"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Dropdown",
      "parent": 162,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Dropdown-173"
        },
        "multiple": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.operatingStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.status || null"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-175"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-176"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-177"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-178"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-179"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-181"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-183"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-185"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 151,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-186"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startORDate"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-188"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-189"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.95rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endORDate"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-190"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creatORDate"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-div-192"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Dropdown",
      "parent": 183,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Dropdown-194"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Dropdown-196"
        },
        "name": {
          "type": "value",
          "value": "room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.anesMethod"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Checkbox-197"
        },
        "label": {
          "type": "value",
          "value": "ใช้วิธีระงับความรู้สึก"
        },
        "name": {
          "type": "value",
          "value": "anesMethod"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesMethod"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"15px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-198"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - นามสกุล"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-199"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-200"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Case"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-201"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "Status"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-202"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-203"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "label",
      "parent": 179,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-204"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": "วันจองห้องผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-205"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-206"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-207"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-209"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-210"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "label",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-label-211"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Checkbox",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Checkbox-212"
        },
        "label": {
          "type": "value",
          "value": "OR Request ไม่ระบุนัดหมาย"
        },
        "name": {
          "type": "value",
          "value": "no_date_no_room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNoDateRoom"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.65rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 150,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueueUX-FormField-213"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Checkbox",
      "parent": 213,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isShowActiveStatus"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.status !== \"\""
        },
        "id": {
          "type": "value",
          "value": "CardORQueueUX-Checkbox-214"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายการที่ไม่ถูกยกเลิก"
        },
        "name": {
          "type": "value",
          "value": "isShowActiveStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeIsShowActiveStatus"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"26px\" }"
        }
      },
      "seq": 215,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null
  },
  "width": 80
}

*********************************************************************************** */
